


const FourZeroFour: React.FC = () => {
    return (
       <section className="fourzerofour">
         <div className="container">
                <h2>404</h2>
                <h5>Page Not Found</h5>
         </div>
       </section>
    );
};

export default FourZeroFour;
import InsideHeader from "../../components/InsideHeader";
import TextWithHeading from "../../components/TextWithHeading";
import ImageBanner from "../../components/ImageBanner";



const WhyRecyclamine: React.FC = () => {



    return (
        <>
            <InsideHeader imgsrc={`${process.env.PUBLIC_URL}/images/the-recyclamine-revolution/Why-Recyclamine/Why-Recyclamine-Banner.jpg`} title="" alt_title="Why Recyclamine"
            />

            <TextWithHeading heading="Businesses">
                <p><strong>Recyclamine Technology stands as the perfect sustainable choice for diverse application segments and epoxy thermoset manufacturing processes.</strong></p>
                <p>Our innovative solutions not only enhance performance but also align with our commitment to protecting the planet. Together, we are taking bold strides toward a sustainable future, empowering individuals, and creating meaningful change for a better world. Join us in this mission to innovate responsibly and make a lasting impact!
                </p>
            </TextWithHeading>
            <ImageBanner imgsrc={`${process.env.PUBLIC_URL}/images/the-recyclamine-revolution/Why-Recyclamine/Businesses.jpg`} alt_title="Businesses" />

            <TextWithHeading heading="Individuals">
                <p className="text-danger"><strong>Recyclamine Technology stands as the perfect sustainable choice for diverse application segments and epoxy thermoset manufacturing processes.</strong></p>
                <p className="text-danger">Our innovative solutions not only enhance performance but also align with our commitment to protecting the planet. Together, we are taking bold strides toward a sustainable future, empowering individuals, and creating meaningful change for a better world. Join us in this mission to innovate responsibly and make a lasting impact!
                </p>
            </TextWithHeading>

            <ImageBanner imgsrc={`${process.env.PUBLIC_URL}/images/the-recyclamine-revolution/Why-Recyclamine/Individuals.jpg`} alt_title="Individuals"/>
            


        </>
    )
}

export default WhyRecyclamine;

import { Link } from "react-router-dom";
import FadeUp from "../../Animation/FadeUp";

export default function PastEvents() {
  return (
    <section className="past-events">
      <div className="container">
        <div className="content-wrapper">
          <FadeUp duration={1} delay={0.2} threshold={0.2}>
          <h5>Past Events</h5>
          <ul>
            <li><Link to={"#"}>
              <h6>Events and experiences</h6>
              <p>Join our interesting events and experiencesto get to know lots of women</p>
                <p className="event-date">March 23-23, 2024  |  New Delhi</p>
             </Link></li>

            <li><Link to={"#"}>
              <h6>Events and experiences</h6>
              <p>Join our interesting events and experiencesto get to know lots of women</p>
                <p className="event-date">March 23-23, 2024  |  New Delhi</p>
             </Link></li>

            <li><Link to={"#"}>
              <h6>Events and experiences</h6>
              <p>Join our interesting events and experiencesto get to know lots of women</p>
                <p className="event-date">March 23-23, 2024  |  New Delhi</p>
             </Link></li>
          </ul>
          </FadeUp>
        </div>
      </div>
    </section>
  );
}

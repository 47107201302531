import React, { useState } from 'react';

interface AccordionItem {
    title: string;
    content: string | string[];
}

interface AccordionProps {
    items: AccordionItem[];
}

const Accordion: React.FC<AccordionProps> = ({ items }) => {
    const [openIndex, setOpenIndex] = useState<number | null>(null);

    const toggleAccordion = (index: number) => {
        setOpenIndex(openIndex === index ? null : index); // Toggle open/close
    };

    return (
        <div className="accordion">
            {items.map((item, index) => (
                <div
                    key={index}
                    className={`accordion-item ${openIndex === index ? 'open' : ''}`}
                >
                    <div
                        className="accordion-header"
                        onClick={() => toggleAccordion(index)}
                    >
                        <h6>{item.title}</h6>
                        <span>{openIndex === index ? '' : ''}</span>
                    </div>
                    {openIndex === index && (
                        <div className="accordion-content">
                            {Array.isArray(item.content) ? (
                                <ul>
                                    {item.content.map((contentItem, i) => (
                                        <li key={i}>{contentItem}</li>
                                    ))}
                                </ul>
                            ) : (
                                <p>{item.content}</p>
                            )}
                        </div>
                    )}
                </div>
            ))}
        </div>
    );
};

export default Accordion;

import { Link, useLocation } from 'react-router-dom';

// interface BreadcrumbProps{
    
// }


const Breadcrumb: React.FC = () => {

    // Utility function to format the breadcrumb labels
    const formatLabel = (segment: string): string => {
        // Replace hyphens with spaces and capitalize each word
        return segment
            .replace(/-/g, ' ') // Replace hyphens with spaces
            .split(' ') // Split by space to handle multi-word labels
            .map((word) => word.charAt(0).toUpperCase() + word.slice(1)) // Capitalize each word
            .join(' '); // Join them back into a single string
    };



    const location = useLocation();
    const pathnames = location.pathname.split('/').filter(x => x);




    return (
        <>
            <ol className="breadcrumb">
                <li className="breadcrumb-item"><Link to={'/'}>Home</Link></li>
                {pathnames.map((value, index) => {
                    const path = `/${pathnames.slice(0, index + 1).join('/')}`;
                    return (
                        <li key={index} className="breadcrumb-item">
                            <Link to={path}>{formatLabel(value)}</Link>
                        </li>
                    );
                })}

            </ol>

        </>
    );
};

export default Breadcrumb;
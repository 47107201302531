import FadeUp from "../Animation/FadeUp"


export default function Process() {
  return (
      <section className='process-diagram'>
        <div className="container">
          <ul>
          <li>
            <FadeUp duration={1} delay={0.1} threshold={0.1}>
              <img src={`${process.env.PUBLIC_URL}/images/homepage/process/Effortless Integration, Maximum Advantage.svg`} alt="Effortless Integration, Maximum Advantage" />
             <p>Effortless Integration, Maximum Advantage</p>
            </FadeUp>
            </li>
          <li>
            <FadeUp duration={1} delay={0.2} threshold={0.2}>
              <img src={`${process.env.PUBLIC_URL}/images/homepage/process/Versatile Application.svg`} alt="Versatile Application" />
              <p>Versatile Application</p>
            </FadeUp>
            </li>
          <li>
            <FadeUp duration={1} delay={0.3} threshold={0.3}>
              <img src={`${process.env.PUBLIC_URL}/images/homepage/process/Valuable Recovered Material.svg`} alt="Valuable Recovered Material" />
              <p>Valuable Recovered Material</p>
            </FadeUp>
            </li>
          <li>
            <FadeUp duration={1} delay={0.4} threshold={0.4}>
              <img src={`${process.env.PUBLIC_URL}/images/homepage/process/Performance Driven.svg`} alt="Performance Driven" />
              <p>Performance Driven</p>
            </FadeUp>
            </li>
          <li>
            <FadeUp duration={1} delay={0.5} threshold={0.5}>
              <img src={`${process.env.PUBLIC_URL}/images/homepage/process/Value AT The End of The Life.svg`} alt="Value AT The End of The Life" />
              <p>Value AT The End of The Life</p>
            </FadeUp>
            </li>
          <li>
            <FadeUp duration={1} delay={0.6} threshold={0.6}>
              <img src={`${process.env.PUBLIC_URL}/images/homepage/process/Certified for Trust and Excellence.svg`} alt="Certified for Trust and Excellence" />
              <p>Certified for Trust and Excellence</p>
            </FadeUp>
            </li>
          <li>
            <FadeUp duration={1} delay={0.7} threshold={0.7}>
              <img src={`${process.env.PUBLIC_URL}/images/homepage/process/Zero Waste Manufacturing.svg`} alt="Zero Waste Manufacturing" />
              <p>Zero Waste Manufacturing</p>
            </FadeUp>
            </li>
          <li>
            <FadeUp duration={1} delay={0.8} threshold={0.8}>
              <img src={`${process.env.PUBLIC_URL}/images/homepage/process/Efficient Recycling Process.svg`} alt="Efficient Recycling Process" />
              <p>Efficient Recycling Process</p>
            </FadeUp>
            </li>
          </ul>
        </div>


      {/* <FadeUp duration={1} delay={0.2} threshold={0.2}><img src={`${process.env.PUBLIC_URL}/images/homepage/process/process-diagram.svg`} alt='Recyclamine' /></FadeUp> */}



      </section>
  )
}

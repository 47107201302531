import PastEvents from './PastEvents';
import UpcomingEvents from './UpcomingEvents';


export default function index() {
   return (
      <section className='events'>
         <div className='container'>
            <div className="row g-0">
               <div className="col-12 col-sm-12 col-md-12 col-lg-6">
                  <PastEvents />
               </div>
               <div className="col-12 col-sm-12 col-md-12 col-lg-6">
                  <UpcomingEvents />
               </div>
            </div>
         </div>
      </section>
   );
}

import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const ScrollToTop = () => {
    const location = useLocation();

    useEffect(() => {
        // Whenever the route changes, scroll to the top
        window.scrollTo(0, 0);
    }, [location]); // Runs every time the location changes

    return null; // This component doesn't render anything
};

export default ScrollToTop;

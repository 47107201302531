// import React, { useState } from "react";

import SplitHeroSection from "../../components/SplitHeroSection";
import InsideVideoHeader from "../../components/InsideVideoHeader";




// import Slider from "../../components/AccordionSlider";

// const slides = [
//     {
//         id: "0",
//         header: "Sustainability and Environmental Responsibility",
//         description: "Offering eco-friendly alternatives to traditional epoxy resins, this pillar emphasizes the product's role in reducing environmental impact, supporting a circular economy, and meeting the growing demand for green manufacturing practices.",
//         image: "Performance and Reliability.jpg"
//     },
//     {
//         id: "1",
//         header: "Innovation and Technological Advancement",
//         description: "Recyclamine represents a significant technological breakthrough in the materials science and composites industry, providing a recyclable solution without compromising performance. It demonstrates the brand's commitment to R&D, pushing the boundaries of what's possible in epoxy resin technology.",
//         image: "Performance and Reliability.jpg"
//     },
//     {
//         id: "2",
//         header: "Performance and Reliability",
//         description: "Ensuring that product performance, durability, and reliability remain top-notch, meeting or exceeding the industry standards set by traditional, non-recyclable epoxies.",
//         image: "Performance and Reliability.jpg"
//     },
//     {
//         id: "3",
//         header: "Versatility and Application Diversity",
//         description: "Catering to a wide range of industries and applications, from wind turbine blades and automotive components to sports equipment and beyond, highlighting its adaptability to different manufacturing needs and product specifications.",
//         image: "Performance and Reliability.jpg"
//     },
//     {
//         id: "4",
//         header: "Cost-effectiveness and Economic Viability",
//         description: "By focusing on the lifecycle costs and the economic benefits of using recyclable materials, Recyclamine positions itself as a cost-effective solution for manufacturers looking to invest in sustainability.",
//         image: "Performance and Reliability.jpg"
//     }
// ];




const OurPhilosophyAndValues: React.FC = () => {
    // const [id, setID] = useState<null | string>("0");
    return (
        <>
            <InsideVideoHeader vidsrc={`${process.env.PUBLIC_URL}/video/banners/our-philosophy.mp4`} />
            <SplitHeroSection contenttype="contentlarge" layout_order='reverse' subheading='' heading='Recyclamine is built on the philosophy of “The Planet is Mine”. ' description={
                <>
                    It has a significant presence in specialty chemicals value chain through offerings in epoxy, food phosphates, sulphites, and water treatment chemicals.A principle that emphasises shared responsibility for the environment
                    <br />
                    <br />
                    Recyclamine’s approach to recycling is holistic, focused on creating value while reducing waste across every industry it serves, and ultimately impacting in the long-term health and sustainability of the planet.
                </>} url='' imgsrc={`${process.env.PUBLIC_URL}/images/philosophy-and-values/the-planet-is-mine-cover.jpg`} theme='light' />

            <section className="contentwithicon philosophy">
                <div className="sectionbg">
                    <img src={`${process.env.PUBLIC_URL}/images/about/abc-bg.jpg`} alt="" /></div>
                <div className="caption">
                    <div className="container">
                        <div className="heading">
                            <h3>The brand’s value pillars encapsulate the essence of Recyclamine and its contribution to advancing sustainable manufacturing practices.</h3>
                        </div>
                        <div className="row align-items-center">
                            <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                                <div className="content-wrapper">
                                    <img src={`${process.env.PUBLIC_URL}/images/philosophy-and-values/brand-value-pillers.png`} alt="" />
                                    </div>
                            </div>
                        </div>


                        {/* <div className="card-panels row row-cols-1 row-cols-lg-5">
                            <div className="card-panel col">
                                <div className="card-panel-inner">
                                    <div className="card-panel-header">
                                        <h4>Innovation and Technological Advancement</h4>
                                        <img src={`${process.env.PUBLIC_URL}/images/icons/right-arrow-large-white.svg`} alt="" />
                                    </div>
                                    <div className="card-panel-img">
                                        <img src={`${process.env.PUBLIC_URL}/images/philosophy-and-values/brand-value-pillers/Performance and Reliability.jpg`} alt="" />
                                    </div>
                                    <div className="card-panel-desc">
                                        <h5>Sustainability and Environmental Responsibility</h5>
                                        <p>Offering eco-friendly alternatives to traditional epoxy resins, this pillar emphasizes the product's role in reducing environmental impact, supporting a circular economy, and meeting the growing demand for green manufacturing practices.</p>
                                    </div>
                                </div>
                            </div>

                            <div className="card-panel col">
                                <div className="card-panel-inner">
                                    <div className="card-panel-header">
                                        <h4>Innovation and Technological Advancement</h4>
                                        <img src={`${process.env.PUBLIC_URL}/images/icons/right-arrow-large-white.svg`} alt="" />
                                    </div>
                                    <div className="card-panel-img"> <img src={`${process.env.PUBLIC_URL}/images/philosophy-and-values/brand-value-pillers/Performance and Reliability.jpg`} alt="" /></div>
                                    <div className="card-panel-desc">
                                        <h5>Innovation and Technological Advancement</h5>
                                        <p>Recyclamine represents a significant technological breakthrough in the materials science and composites industry, providing a recyclable solution without compromising performance. It demonstrates the brand's commitment to R&D, pushing the boundaries of what's possible in epoxy resin technology.</p>
                                    </div>
                                </div>
                            </div>

                            <div className="card-panel col">
                                <div className="card-panel-inner">
                                    <div className="card-panel-header">
                                        <h4>Innovation and Technological Advancement</h4>
                                        <img src={`${process.env.PUBLIC_URL}/images/icons/right-arrow-large-white.svg`} alt="" />
                                    </div>
                                    <div className="card-panel-img"> <img src={`${process.env.PUBLIC_URL}/images/philosophy-and-values/brand-value-pillers/Performance and Reliability.jpg`} alt="" /></div>
                                    <div className="card-panel-desc">
                                        <h5>Performance and Reliability</h5>
                                        <p>Ensuring that product performance, durability, and reliability remain top-notch, meeting or exceeding the industry standards set by traditional, non-recyclable epoxies.
                                        </p>
                                    </div>
                                </div>
                            </div>

                            <div className="card-panel col">
                                <div className="card-panel-inner">
                                    <div className="card-panel-header">
                                        <h4>Innovation and Technological Advancement</h4>
                                        <img src={`${process.env.PUBLIC_URL}/images/icons/right-arrow-large-white.svg`} alt="" />
                                    </div>
                                    <div className="card-panel-img"> <img src={`${process.env.PUBLIC_URL}/images/philosophy-and-values/brand-value-pillers/Performance and Reliability.jpg`} alt="" /></div>
                                    <div className="card-panel-desc">
                                        <h5>Versatility and Application Diversity</h5>
                                        <p>Catering to a wide range of industries and applications, from wind turbine blades and automotive components to sports equipment and beyond, highlighting its adaptability to different manufacturing needs and product specifications.
                                        </p>
                                    </div>
                                </div>
                            </div>

                            <div className="card-panel col">
                                <div className="card-panel-inner">
                                    <div className="card-panel-header">
                                        <h4>Innovation and Technological Advancement</h4>
                                        <img src={`${process.env.PUBLIC_URL}/images/icons/right-arrow-large-white.svg`} alt="" />
                                    </div>
                                    <div className="card-panel-img"> <img src={`${process.env.PUBLIC_URL}/images/philosophy-and-values/brand-value-pillers/Performance and Reliability.jpg`} alt="" /></div>
                                    <div className="card-panel-desc">
                                        <h5>Cost-effectiveness and Economic Viability
                                        </h5>
                                        <p>By focusing on the lifecycle costs and the economic benefits of using recyclable materials, Recyclamine positions itself as a cost-effective solution for manufacturers looking to invest in sustainability.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div> */}

                 
                    </div>
                </div>
            </section >


            {/* <div className="container">
                <Slider slides={slides} active={id} callback={setID} />
            </div> */}
        </>
    )
}


export default OurPhilosophyAndValues;

interface HeroStatementProps{
    content: string;
}

const HeroStatement: React.FC<HeroStatementProps> = ({content}) => {
    return (
        <section className="hero-statement grey">
            <div className="container">
                <h4>{content}</h4>
            </div>
        </section>
    );
};

export default HeroStatement;
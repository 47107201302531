import Breadcrumb from "../Breadcrumb";

interface InsideHeaderProps {
    imgsrc: string;
    alt_title?: string;
    title?: string;
}

const InsideHeader: React.FC<InsideHeaderProps> = ({ imgsrc, alt_title, title }) => {
    return (
        <section className="insideheader">
            <div className="bannerimg">
                <img src={imgsrc} alt={alt_title} />
            </div>
             {title && (
                <div className="bannerheading">
                    <div className="container">
                        <h2>{title}</h2>
                    </div>
                </div>
             )}

            <div className="caption">
                <div className="container">
                    <Breadcrumb />
                </div>
            </div>

        </section>
    );
};

export default InsideHeader;

// import Swiper core and required modules
import { Navigation, Pagination, EffectFade, Autoplay, A11y, } from 'swiper/modules';
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';
import { Link } from 'react-router-dom';
import KnowMore from '../TextCTA';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import "swiper/css/effect-fade";


const MainSlider = () => {

    return (
        <div className='mainslider'>
            <Swiper modules={[Navigation, Pagination, Autoplay, A11y, EffectFade]} spaceBetween={0} slidesPerView={1} navigation
                pagination={{ clickable: true }} effect={"fade"}
                speed={1500}
                autoplay={{ delay: 5500 }}
                loop={true}
            >
                <SwiperSlide>
                    {/* <img src={`${process.env.PUBLIC_URL}/images/homepage/mainslider/home-mainslider01.jpg`} alt="" /> */}
                    <video className="lazy" loop muted autoPlay playsInline>
                        <source src="video/home01.mp4" type="video/mp4"/>
                            Your browser does not support the HTML5 Video element.
                    </video>
                    
                    </SwiperSlide>
                <div className='caption'>
                    <div className='container'>
                        <div className='inside-container'>
                            <h1>Revolutionizing<br /> Windmill Sustainability</h1>
                            <KnowMore to='#' title='Know More' color='white'/>
                        </div>
                    </div>
                </div>
            </Swiper>
            <div className='floating-icons'>
                <hr />
                <ul>       
                    <li><Link to={"https://www.facebook.com/recyclamine "} target='_blank'>
                        <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <g filter="url(#filter0_b_491_543)">
                                <path id='bg' d="M17.8235 35.6471C27.6672 35.6471 35.6471 27.6672 35.6471 17.8235C35.6471 7.97987 27.6672 0 17.8235 0C7.97987 0 0 7.97987 0 17.8235C0 27.6672 7.97987 35.6471 17.8235 35.6471Z" fill="#F9F9F9" fill-opacity="0.15" />
                            </g>
                            <path d="M18.2715 14.3335C18.2715 14.4771 18.2715 14.945 18.2715 15.6098H20.6444L20.3871 17.6472H18.2715C18.2715 20.7894 18.2715 25 18.2715 25H15.4633C15.4633 25 15.4633 20.8451 15.4633 17.6472H14V15.6098H15.4633C15.4633 14.8018 15.4633 14.2272 15.4633 14.0673C15.4633 13.3051 15.4036 12.9434 15.7399 12.3528C16.0763 11.7622 17.0252 10.9815 18.6664 11.0003C20.308 11.0198 21 11.1719 21 11.1719L20.6444 13.3434C20.6444 13.3434 19.5962 13.0767 19.0816 13.1718C18.5678 13.2668 18.2715 13.5717 18.2715 14.3335Z" fill="white" />
                            <defs>
                                <filter id="filter0_b_491_543" x="-15" y="-15" width="65.6475" height="65.6484" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                                    <feFlood flood-opacity="0" result="BackgroundImageFix" />
                                    <feGaussianBlur in="BackgroundImageFix" stdDeviation="7.5" />
                                    <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_491_543" />
                                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_backgroundBlur_491_543" result="shape" />
                                </filter>
                            </defs>
                        </svg>
                    </Link></li>             

                        <li><Link to={"https://www.instagram.com/recyclamine_am/"} target='_blank'>
                            <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <g filter="url(#filter0_b_295_79)">
                                    <path id='bg' d="M17.8235 35.7955C27.6672 35.7955 35.6471 27.8156 35.6471 17.972C35.6471 8.1283 27.6672 0.148438 17.8235 0.148438C7.97987 0.148438 0 8.1283 0 17.972C0 27.8156 7.97987 35.7955 17.8235 35.7955Z" fill="#F9F9F9" fill-opacity="0.15" />
                                </g>
                                <path d="M18.3001 15.8789C16.7702 15.8789 15.5244 17.124 15.5244 18.6539C15.5244 20.1845 16.7702 21.4299 18.3001 21.4299C19.8307 21.4299 21.0754 20.1845 21.0754 18.6539C21.0754 17.1243 19.8304 15.8789 18.3001 15.8789Z" fill="#F9F9F9" />
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M22.924 11.3555H13.6768C12.2008 11.3555 11 12.5563 11 14.0323V23.2795C11 24.7555 12.2008 25.9567 13.6768 25.9567H22.924C24.4 25.9567 25.6012 24.7558 25.6012 23.2795V14.0323C25.6012 12.5563 24.4004 11.3555 22.924 11.3555ZM18.3004 23.4629C15.6501 23.4629 13.4939 21.3066 13.4939 18.6561C13.4939 16.0058 15.6501 13.8496 18.3004 13.8496C20.9509 13.8496 23.1072 16.0058 23.1072 18.6561C23.1072 21.3063 20.9506 23.4629 18.3004 23.4629ZM22.1241 13.7061C22.1241 14.3331 22.6343 14.8433 23.2617 14.8433C23.8887 14.8433 24.3989 14.3331 24.3989 13.7061C24.3989 13.079 23.8887 12.5688 23.2617 12.5688C22.6343 12.5688 22.1241 13.079 22.1241 13.7061Z" fill="#F9F9F9" />
                                <defs>
                                    <filter id="filter0_b_295_79" x="-15" y="-14.8516" width="65.6475" height="65.6484" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                                        <feFlood flood-opacity="0" result="BackgroundImageFix" />
                                        <feGaussianBlur in="BackgroundImageFix" stdDeviation="7.5" />
                                        <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_295_79" />
                                        <feBlend mode="normal" in="SourceGraphic" in2="effect1_backgroundBlur_295_79" result="shape" />
                                    </filter>
                                </defs>
                            </svg>


                        </Link></li>

                    <li><Link to={"https://www.linkedin.com/company/recyclamine/"} target='_blank'>
                        <svg width="36" height="37" viewBox="0 0 36 37" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <g filter="url(#filter0_b_295_70)">
                                <path id='bg' d="M17.8235 0.5C7.98026 0.5 0 8.48026 0 18.3235C0 28.1668 7.98026 36.1471 17.8235 36.1471C27.6668 36.1471 35.6471 28.1668 35.6471 18.3235C35.6471 8.48026 27.6668 0.5 17.8235 0.5Z" fill="#F9F9F9" fill-opacity="0.15" />
                            </g>
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M13.15 15.2892H13.1327C12.2684 15.2892 11.708 14.7238 11.708 14.007C11.708 13.2763 12.2849 12.7227 13.1665 12.7227C14.0474 12.7227 14.589 13.2749 14.6063 14.0049C14.6063 14.7217 14.0474 15.2892 13.15 15.2892ZM23.9581 23.6116H21.2506V19.7837C21.2506 18.7817 20.8271 18.0977 19.8958 18.0977C19.1835 18.0977 18.7874 18.5593 18.603 19.0041C18.5421 19.1447 18.5432 19.334 18.5443 19.529C18.5445 19.5555 18.5446 19.5821 18.5446 19.6087V23.6116H15.8624C15.8624 23.6116 15.897 16.8308 15.8624 16.2144H18.5446V17.3753C18.7031 16.8677 19.5602 16.1433 20.928 16.1433C22.6249 16.1433 23.9581 17.2073 23.9581 19.4985V23.6116ZM14.4046 16.2144H12.017V23.6116H14.4046V16.2144Z" fill="white" />
                            <defs>
                                <filter id="filter0_b_295_70" x="-15" y="-14.5" width="65.6475" height="65.6484" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                                    <feFlood flood-opacity="0" result="BackgroundImageFix" />
                                    <feGaussianBlur in="BackgroundImageFix" stdDeviation="7.5" />
                                    <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_295_70" />
                                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_backgroundBlur_295_70" result="shape" />
                                </filter>
                            </defs>
                        </svg>

                
                
                </Link></li>
                    
                    

                    <li><Link to={"https://www.youtube.com/@RecyclamineAdityaBirla"} target='_blank'>
                       {/* <img src={`${process.env.PUBLIC_URL}/images/icons/x-white.svg`} alt='X' /> */}
                        <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <g filter="url(#filter0_b_493_581)">
                                <path id='bg' d="M17.8235 35.6471C27.6672 35.6471 35.6471 27.6672 35.6471 17.8235C35.6471 7.97987 27.6672 0 17.8235 0C7.97987 0 0 7.97987 0 17.8235C0 27.6672 7.97987 35.6471 17.8235 35.6471Z" fill="#F9F9F9" fill-opacity="0.15" />
                            </g>
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M24.9231 11.418C25.7406 11.648 26.3844 12.3256 26.6029 13.1861C27 14.7458 27 18 27 18C27 18 27 21.2541 26.6029 22.8139C26.3844 23.6744 25.7406 24.352 24.9231 24.5821C23.4415 25 17.5 25 17.5 25C17.5 25 11.5585 25 10.0768 24.5821C9.25929 24.352 8.61545 23.6744 8.39695 22.8139C8 21.2541 8 18 8 18C8 18 8 14.7458 8.39695 13.1861C8.61545 12.3256 9.25929 11.648 10.0768 11.418C11.5585 11 17.5 11 17.5 11C17.5 11 23.4415 11 24.9231 11.418ZM15.7188 15.2608V21.3478L20.4688 18.3044L15.7188 15.2608Z" fill="white" />
                            <defs>
                                <filter id="filter0_b_493_581" x="-15" y="-15" width="65.6475" height="65.6484" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                                    <feFlood flood-opacity="0" result="BackgroundImageFix" />
                                    <feGaussianBlur in="BackgroundImageFix" stdDeviation="7.5" />
                                    <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_493_581" />
                                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_backgroundBlur_493_581" result="shape" />
                                </filter>
                            </defs>
                        </svg>

                     </Link></li>

                </ul>
            </div>

            <div className='scrolldown'>
                <Link to={'#'}>Scroll Down <img src={`${process.env.PUBLIC_URL}/images/icons/down-arrow-white.svg`} alt='Scroll Down' /></Link>
            </div>

        </div>
    );
};

export default MainSlider;

import InsideHeader from "../../components/InsideHeader";
import ContentWithIcon from "../../components/ContentWithIcon";
import Teams from "../../components/Teams";
import SplitHeroSection from "../../components/SplitHeroSection";

const TheGenesisofRecyclamine: React.FC = () => {
    return (
        <>


            <InsideHeader imgsrc={`${process.env.PUBLIC_URL}/images/insideheader/the-genesis-of-recyclamine.jpg`} alt_title="The Legacy (ABC + ABAM)" />

            <SplitHeroSection layout_order='reverse' theme='light' subheading='' heading='The Need' description={
                <>
                    <strong>Thermoset plastics have been indispensable across industries due to their strength, but their non-recyclable nature contributes to vast amounts of industrial waste.</strong>
                    <br />
                    <br />
                    As sustainability becomes a prime focus of government norms and regulations, it is ever more essential for millennials and centennials of the market to demand for eco-friendly products. Not only that, their checklist will also include a quick, smooth and seamless transition to sustainable solutions with minimal to no investments into new infrastructure.
                </>} url='' imgsrc={`${process.env.PUBLIC_URL}/images/about/the-need.jpg`} />


            <SplitHeroSection layout_order='' theme='blue' subheading='' heading='Recyclamine is the answer to all these challenges.' description={
                <>
                    Recyclamine’s recyclable epoxy solution addresses this, providing industries with a circular approach to waste while upholding strength and durability.
                </>
            } url='' imgsrc={`${process.env.PUBLIC_URL}/images/about/recyclamine-is-the-answer-to-all-these-challenges.jpg`} />




            {/* <Solutions theme='light' subheading='' heading='The Need' description={
                <>
                    <strong>Thermoset plastics have been indispensable across industries due to their strength, but their non-recyclable nature contributes to vast amounts of industrial waste.</strong>
                    <br />
                    <br />
                    As sustainability becomes a prime focus of government norms and regulations, it is ever more essential for millennials and centennials of the market to demand for eco-friendly products. Not only that, their checklist will also include a quick, smooth and seamless transition to sustainable solutions with minimal to no investments into new infrastructure.
                </>
            } url='' imgsrc={`${process.env.PUBLIC_URL}/images/about/the-need.jpg`} /> */}

            {/* <Solutions theme='light' subheading='' heading='Recyclamine is the answer to all these challenges.' description={
                <>
                    Recyclamine’s recyclable epoxy solution addresses this, providing industries with a circular approach to waste while upholding strength and durability.
                </>
            } url='' imgsrc={`${process.env.PUBLIC_URL}/images/about/recyclamine-is-the-answer-to-all-these-challenges.jpg`} /> */}

            <ContentWithIcon bgimg={`${process.env.PUBLIC_URL}/images/about/abc-bg.jpg`} heading={
                <>
                    The Technology: Recyclamine’s patented technology is a recyclable thermoset epoxy that enables recovery of fibers and epoxy without sacrificing mechanical performance.
                </>}
                description="This innovation is based on closed-loop recycling, allowing materials to return to the production cycle.Recyclamine technology is designed to interface seamlessly with current systems to optimise performance while driving eco-friendly initiatives to reuse and recycle materials, ultimately paving the way for sustainability in a more circular economy." imgsrc={`${process.env.PUBLIC_URL}/images/Recyclamine-Logo.svg`} />

            <Teams />





        </>
    );
};

export default TheGenesisofRecyclamine;
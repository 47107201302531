import React from 'react';
import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';


// Bootstrap CSS
import "bootstrap/dist/css/bootstrap.min.css";
// Bootstrap Bundle JS (includes Popper for dropdowns, modals, etc.)
import "bootstrap/dist/js/bootstrap.bundle.min";

import { BrowserRouter, Route, Routes } from "react-router-dom";

import { CommonProvider } from "./contexts/commonContext";
import Layout from './Layout';

// Pages
import Home from "./pages/Home";
import FourZeroFour from './pages/404';

// About pages

import TheLegacy from './pages/About/TheLegacy';
import TheGenesisofRecyclamine from './pages/About/TheGenesisofRecyclamine';
import OurPhilosophyAndValues from './pages/About/OurPhilosophyAndValues';
import About from './pages/About';

// The Recyclamine Revolution

import TechnologyHowItWorks from './pages/TheRecyclamineRevolution/TechnologyHowItWorks';
import WhyRecyclamine from './pages/TheRecyclamineRevolution/WhyRecyclamine';

import ScrollToTop from './components/ScrollToTop';


// Applications

import Applications from './pages/Applications';
import ApplicationsDetail from './pages/Applications/detail';
// import ApplicationsDetailSkis from './pages/Applications/skis';


const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);


root.render(
  <React.StrictMode>
    <CommonProvider>
      <BrowserRouter>
        <ScrollToTop />
        <Routes>
          {/* Define Layout as a wrapper for all pages */}
          
          <Route element={<Layout />}>
            <Route index element={<Home />} />
            {/* ABOUT */}
            <Route path="about/" element={<About />} />

            <Route path="about/the-legacy-abc-abam" element={<TheLegacy />} />
            <Route path="about/the-genesis-of-recyclamine" element={<TheGenesisofRecyclamine />} />
            <Route path="about/our-philosophy-and-values" element={<OurPhilosophyAndValues />} />
            {/* The Recyclamine Revolution */}
            <Route path="the-recyclamine-revolution/the-technology-and-how-it-works" element={<TechnologyHowItWorks />} />
            <Route path="the-recyclamine-revolution/why-recyclamine" element={<WhyRecyclamine />} />



            <Route path="*" element={<FourZeroFour />} />


            {/* Applications */}

            <Route path='applications' element={<Applications/>} />
            {/* <Route path='applications/Skis' element={<ApplicationsDetailSkis />} /> */}
            <Route path="/applications/:id" element={<ApplicationsDetail />} />

            {/* <Route path='applications/detail' element={<ApplicationsDetail/>} /> */}


          </Route>
        </Routes>
      </BrowserRouter>
    </CommonProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

import InsideHeader from "../../components/InsideHeader";
import HeroStatement from "../../components/HeroStatement";
import { Link } from "react-router-dom";
import ImageWithCaption from "../../components/ImageWithCaption";


import applicationsData from "../../data/applications.json"; // Import JSON data


// Define the structure of each application
interface ApplicationProps {
    id: string;
    title: string;
    thumbImage: string;
    bannerImage: string;
    shortDesc: string;
    details: string;
    sections: Array<{
        title: string;
        subTitle: string;
        content: string | string[];
        contentImg: string;
    }>;
    clients: string[];
    // timeline: Array<{
    //     title: string;
    //     description: string;
    //     image: string;
    // }>;
    faq: Array<{
        title: string;
        content: string;
    }>;
    // whyCare: string[];
    // recyclamine: string;
}



const Applications: React.FC = () => {
    return (
        <>
            <InsideHeader imgsrc={`${process.env.PUBLIC_URL}/images/applications/Applications-banner.jpg`} alt_title="Applications" />
            <HeroStatement content="Discover how Recyclamine’s revolutionary recyclable epoxy technology transforms industries by providing sustainable, high-performance solutions for composite materials. Explore each industry to understand how Recyclamine’s recyclable epoxy can support your performance and sustainability goals." />
            <section className="applications">
                <div className="container">
                    <div className="row gy-4 row-cols-1 row-cols-lg-3">
                        {applicationsData.map((appList: ApplicationProps) => (
                            <div className="col" key={appList.id}>
                                <div className="application-card">
                                    <Link to={appList.id}>
                                        <div className="application-card-img">
                                            <img src={`${process.env.PUBLIC_URL}/images/applications/${appList.thumbImage}`} alt={appList.title} />
                                        </div>
                                        <div className="application-card-description">
                                            <h4>{appList.title}</h4>
                                            <p>{appList.shortDesc}</p>
                                        </div>
                                        <button type="button"><img src={`${process.env.PUBLIC_URL}/images/icons/circle-arrow-white.svg`} alt="" /></button>
                                    </Link>
                                </div>
                            </div>
                        ))}





{/* 
                        <div className="col">
                            <div className="application-card">
                                <Link to="/applications/skis">
                                    <div className="application-card-img">
                                        <img src={`${process.env.PUBLIC_URL}/images/applications/Skis/Skis-thumb.jpg`} alt="" />
                                    </div>
                                    <div className="application-card-description">
                                        <h4>Skis<br />&nbsp;</h4>
                                        <p>For skis that demand high durability and flexibility, Recyclamine’s recyclable epoxy provides enhanced performance and sustainability. We’re helping the industry reduce environmental impact while maintaining the resilience skiers rely on.</p>
                                    </div>
                                    <button type="button"><img src={`${process.env.PUBLIC_URL}/images/icons/circle-arrow-white.svg`} alt="" /></button>
                                </Link>
                            </div>
                        </div>

                        <div className="col">
                            <div className="application-card">
                                <Link to="#">
                                    <div className="application-card-img">
                                        <img src={`${process.env.PUBLIC_URL}/images/applications/Snowboards&Surfboards/Snowboards&Surfboards-Cover.jpg`} alt="" />
                                    </div>
                                    <div className="application-card-description">
                                        <h4>Snowboards & Surfboards</h4>
                                        <p>Snowboards and surfboards crafted with Recyclamine’s epoxy excel in strength, flexibility, and responsiveness. Our solution not only delivers superior performance but also promotes a circular approach, making equipment disposal environmentally responsible, and setting new standards in sustainability for sports.</p>
                                    </div>
                                    <button type="button"><img src={`${process.env.PUBLIC_URL}/images/icons/circle-arrow-white.svg`} alt="" /></button>
                                </Link>
                            </div>
                        </div>

                        <div className="col">
                            <div className="application-card">
                                <Link to="#">
                                    <div className="application-card-img">
                                        <img src={`${process.env.PUBLIC_URL}/images/applications/Kayaks&Surfboats/Kayaks&Surfboats-Cover.jpg`} alt="" />
                                    </div>
                                    <div className="application-card-description">
                                        <h4>Kayaks <br />&nbsp;</h4>
                                        <p>We understand that durability and impact resistance are essential to a perfect kayak. Recyclamine’s recyclable epoxy ensures that kayaks withstand rugged use while supporting sustainability, reducing the ecological footprint of watersports gear.</p>
                                    </div>
                                    <button type="button"><img src={`${process.env.PUBLIC_URL}/images/icons/circle-arrow-white.svg`} alt="" /></button>
                                </Link>
                            </div>
                        </div>


                        <div className="col">
                            <div className="application-card">
                                <Link to="#">
                                    <div className="application-card-img">
                                        <img src={`${process.env.PUBLIC_URL}/images/applications/Wind blade Spar Cap/WindbladeSparCap-Cover.jpg`} alt="" />
                                    </div>
                                    <div className="application-card-description">
                                        <h4>Wind Blade/<br/> Spar Cap</h4>
                                        <p>Wind energy demands materials that can withstand intense stress and long-term environmental exposure. Recyclamine’s epoxy solution not only meets these performance needs but also makes blade recycling feasible, driving a new era of circularity in renewable energy.</p>
                                    </div>
                                    <button type="button"><img src={`${process.env.PUBLIC_URL}/images/icons/circle-arrow-white.svg`} alt="" /></button>
                                </Link>
                            </div>
                        </div>


                        <div className="col">
                            <div className="application-card">
                                <Link to="#">
                                    <div className="application-card-img">
                                        <img src={`${process.env.PUBLIC_URL}/images/applications/Mobility Transportation/MobilityTransportation-Cover.jpg`} alt="" />
                                    </div>
                                    <div className="application-card-description">
                                        <h4>Mobility/ Transportation</h4>
                                        <p>For the mobility and transportation sectors, Recyclamine’s recyclable epoxy composites bring strength, weight reduction, and recyclability to key structural components, enhancing fuel efficiency and enabling sustainable innovation across the industry.</p>
                                    </div>
                                    <button type="button"><img src={`${process.env.PUBLIC_URL}/images/icons/circle-arrow-white.svg`} alt="" /></button>
                                </Link>
                            </div>
                        </div>


                        <div className="col">
                            <div className="application-card">
                                <Link to="#">
                                    <div className="application-card-img">
                                        <img src={`${process.env.PUBLIC_URL}/images/applications/Hydrogen Cylinder/Hydrogen Cylinder-Cover.jpg`} alt="" />
                                    </div>
                                    <div className="application-card-description">
                                        <h4>Hydrogen Cylinder

</h4>
                                        <p>Hydrogen storage solutions require materials with high-pressure resistance and reliability. Recyclamine’s epoxy meets these demands while allowing for recycling at the product’s end of life, making sustainable energy solutions more accessible.


</p>
                                    </div>
                                    <button type="button"><img src={`${process.env.PUBLIC_URL}/images/icons/circle-arrow-white.svg`} alt="" /></button>
                                </Link>
                            </div>
                        </div>


                        <div className="col">
                            <div className="application-card">
                                <Link to="#">
                                    <div className="application-card-img">
                                        <img src={`${process.env.PUBLIC_URL}/images/applications/Electrical & Electronics/Electrical & Electronics-Cover.jpg`} alt="" />
                                    </div>
                                    <div className="application-card-description">
                                        <h4>Electrical &<br/>Electronics
</h4>
                                        <p>The electronics industry benefits from Recyclamine’s epoxy with applications in printed circuit boards and insulation that require durability, resistance, and sustainability, contributing to eco-friendly product lifecycles.
</p>
                                    </div>
                                    <button type="button"><img src={`${process.env.PUBLIC_URL}/images/icons/circle-arrow-white.svg`} alt="" /></button>
                                </Link>
                            </div>
                        </div>


                        <div className="col">
                            <div className="application-card">
                                <Link to="#">
                                    <div className="application-card-img">
                                        <img src={`${process.env.PUBLIC_URL}/images/applications/Drones/Drones-Cover.jpg`} alt="" />
                                    </div>
                                    <div className="application-card-description">
                                        <h4>Drones<br />&nbsp;</h4>
                                        <p>Weight and strength are critical for drones. That’s why we have ensured that, Recyclamine provides a lightweight and recyclable epoxy solution, enabling advanced designs that don’t compromise on environmental impact.</p>
                                    </div>
                                    <button type="button"><img src={`${process.env.PUBLIC_URL}/images/icons/circle-arrow-white.svg`} alt="" /></button>
                                </Link>
                            </div>
                        </div> */}

{/* 
                        <div className="col">
                            <div className="application-card">
                                <Link to="#">
                                    <div className="application-card-img">
                                        <img src={`${process.env.PUBLIC_URL}/images/applications/`} alt="" />
                                    </div>
                                    <div className="application-card-description">
                                        <h4></h4>
                                        <p></p>
                                    </div>
                                    <button type="button"><img src={`${process.env.PUBLIC_URL}/images/icons/circle-arrow-white.svg`} alt="" /></button>
                                </Link>
                            </div>
                        </div>


                        <div className="col">
                            <div className="application-card">
                                <Link to="#">
                                    <div className="application-card-img">
                                        <img src={`${process.env.PUBLIC_URL}/images/applications/`} alt="" />
                                    </div>
                                    <div className="application-card-description">
                                        <h4></h4>
                                        <p></p>
                                    </div>
                                    <button type="button"><img src={`${process.env.PUBLIC_URL}/images/icons/circle-arrow-white.svg`} alt="" /></button>
                                </Link>
                            </div>
                        </div> */}

                    </div>
                </div>
            </section>


            <ImageWithCaption bgimg={`${process.env.PUBLIC_URL}/images/applications/Applications-banner-bottom.jpg`} heading="Want to know more about how epoxy can be truly recycled to change the world?" url="#" link_title="Know More"/>

        </>
    );
};

export default Applications;

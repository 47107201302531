import FadeUp from "../Animation/FadeUp";

interface ContentWithIconProps {
    bgimg: string,
    heading: any;
    description: any,
    imgsrc: string,
}

const ContentWithIcon: React.FC<ContentWithIconProps> = ({ bgimg, heading, description, imgsrc }) => {
    return (
        <section className="contentwithicon">
            <div className="sectionbg">
                <img src={bgimg} alt={heading} />
             </div>
            <div className="caption">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-12 col-sm-12 col-md-12 col-lg-6">
                            <div className="content-wrapper">
                                 <FadeUp duration={1} delay={0.2} threshold={0.2}>
                                <h4>{heading}</h4>
                                <p>{description}</p>
                                </FadeUp>
                            </div>
                        </div>
                        <div className="col-12 col-sm-12 col-md-12 col-lg-4 offset-lg-2">
                            <div className="image-wrapper">
                                <FadeUp duration={1} delay={0.2} threshold={0.2}>
                                    <img src={imgsrc} alt={heading} />
                                </FadeUp>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default ContentWithIcon;
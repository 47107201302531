// Home.tsx
import Swiper from '../components/MainSlider';
import SplitHeroSection from '../components/SplitHeroSection';
import HeroSection from '../components/HeroSection';
import HeroVideo from '../components/HeroVideo';
// import Solutions from '../components/Solutions';
import Process from '../components/Process';
import CaseStudies from '../components/CaseStudies';
import Events from '../components/Events';
import SocialFeed from '../components/SocialFeed';
import KnowMore from '../components/TextCTA';

const Home: React.FC = () => {

  const caseStudiesData = [
    {
      headingtype: 'large',
      subheading: 'Case Studies',
      heading: 'Revolutionary Material Use',
      description: 'Cobra, with Aditya Birla Chemicals, incorporated Recyclamine technology into the production of watersports fin moulds using recyclable epoxy, enabling efficient closed-loop recycling.',
      link_title: 'Know more',
      url: '#',
      imgsrc: '/images/homepage/case-studies/Revolutionary Material Use.jpg',
    },
    // {
    //   headingtype: 'large',
    //   subheading: 'Case Study',
    //   heading: 'Study Heading 2',
    //   description: 'Description for case study 2.',
    //   link_title: 'Learn more',
    //   url: '/case-study-2',
    //   imgsrc: '/images/homepage/case-studies/Revolutionary Material Use.jpg',
    // },
  ];





  return (
    <>
      <Swiper />
      <section className='hmabout'>
        <div className='container'>
          <div className='eyebrow-text'>
            <h5>
              <img src={`${process.env.PUBLIC_URL}/images/Recyclamine-Icon-Colored.svg`} alt="Logo" />              
               ABOUT</h5>
          </div>
          <div className='row'>
            <div className='col-12 col-sm-12 col-md-12 col-lg-4'>
              <div className='hmabout-lhs'>
                <h4>I'm a great place for you to tell a story and let your users know a little more about you.</h4>
              </div>
            </div>
            <div className='col-12 col-sm-12 col-md-12 col-lg-7 offset-lg-1'>
              <div className='hmabout-rhs'>
                <p>I'm a paragraph. Click here to add your own text and edit me. It's
                  easy. Just click "Edit Text" or double click me to add your own
                  content and make changes to the font. Feel free to drag and drop
                  me anywhere you like on your page. I'm a great place for you to
                  tell a story and let your users know a little more about you. Lorem ipsum dolor sit amet consectetur adipisicing elit. Et sunt, labore, libero tempore sit alias praesentium eaque deserunt ipsam atque non rerum tenetur vitae odio, soluta temporibus obcaecati veniam distinctio?</p>
                <KnowMore to="#" title="Know More" color="blue" />
              </div>
            </div>
          </div>
        </div>
      </section>




      <SplitHeroSection theme='light' layout_order='reverse' contenttype='' subheading='Revolution' heading='The Recyclamine Revolution ' description={
        <>
          Introducing Recyclamine, a ground-breaking advancement in thermoset composites that revitalizes non-recyclable epoxy composites, establishing a path to perpetual recyclability. Recyclamine Technology works by…
        </>} url='#' imgsrc={`${process.env.PUBLIC_URL}/images/homepage/about/home-about.jpg`} />


      <HeroSection />
      <HeroVideo />

      {/* <Solutions theme='grey' subheading='Solutions' heading='Applications and Solutions' description={[<strong>Transforming Composites, Renovating the Future across Industries</strong>, <br/>, "Our innovative epoxy solutions deliver unmatched performance while driving sustainability, creating lasting benefits for your business and the planet."]} url='#' imgsrc={`${process.env.PUBLIC_URL}/images/homepage/solutions/Solutions-Cover.jpg`} /> */}


      <SplitHeroSection theme='grey' layout_order='reverse' subheading='Solutions' heading='Applications and Solutions ' description={[<strong>Transforming Composites, Renovating the Future across Industries</strong>, <br />, "Our innovative epoxy solutions deliver unmatched performance while driving sustainability, creating lasting benefits for your business and the planet."]} url='#' imgsrc={`${process.env.PUBLIC_URL}/images/homepage/solutions/Solutions-Cover.jpg`} />




      <Process />

      {/* <CaseStudies /> */}



      <CaseStudies caseStudies={caseStudiesData} />










      {/* <CaseStudies headingtype='large' subheading='Case Studies' heading='Revolutionary Material Use' description={<><p>Cobra, with Aditya Birla Chemicals, incorporated Recyclamine technology into the production of watersports fin moulds using recyclable epoxy, enabling efficient closed-loop recycling.</p>
        </>}
        link_title='Know More' url='#' imgsrc={`${process.env.PUBLIC_URL}/images/homepage/case-studies/Revolutionary Material Use.jpg`} /> */}

      <Events />
      <SocialFeed />
    </>
  );
};

export default Home;
